.phone-input {
    width: 100%;
    position: relative;
    .PhoneInputInput {
        width: 100%;
        border-radius: 49px;
        font-family: "Poppins", sans-serif;
        padding: 0.75rem 0.75rem 0.75rem 50px;
        font-size: 0.75rem;
        border-color: #f2f2f2;
        border-color: transparent;
        line-height: 1.4;
        line-height: 1;
        background-color: #f2f2f2;
            outline: 0;
            @media (min-width: 1921px) {
                font-size: 18px;
              }
    }
                .PhoneInputInput:focus {
        
            transition-duration: 0.3s;
                border-color: #7A8CBF;
            }
    .PhoneInputCountry {
        position: absolute;
        top: 30%;
        left: 5%;
    }
}